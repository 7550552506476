import io from "socket.io-client";

console.log(`ENV: ${process.env.REACT_APP_MIROTALK_BASE_URL}`);

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const socket = io(`${process.env.REACT_APP_MIROTALK_BASE_URL}`, {
  path: "/notification",
  query: { token: userInfoFromStorage ? userInfoFromStorage.token : "" },
});

export default socket;
